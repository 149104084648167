export class UploadUtil {
    private static units = [ 'bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' ];
    private static validImageFormats = [ 'jpg', 'jpeg', 'png', 'gif' ];

    public static convertToBytes(size: number): string {
        let level = 0;

        while (size >= 1024 && ++level) {
            size = size / 1024;
        }

        return size.toFixed(size < 10 && level > 0 ? 1 : 0) + ' ' + this.units[level];
    }

    public static isValidImageFormat(fileExt: string) {
        return this.validImageFormats.includes(fileExt);
    }

    public static isValidImage(file: File): boolean {
        const fileExt = this.getFileExtension(file);

        if (!this.validImageFormats.includes(fileExt)) {
            window.alert(`Not supported format: ${ fileExt }, please upload jpg, jpeg, png or gif file.`);
            return false;
        }

        if (file.size > 5000000) {
            window.alert('File size should not exceed 5MB');
            return false;
        }

        return true;
    }

    public static convertToBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsBinaryString(file);
            reader.onload = event => resolve(btoa(event.target!.result!.toString()));
            reader.onerror = error => reject(error);
        });
    }

    public static getFileExtension(file: File): string {
        return file?.name?.split('.').pop() ?? '';
    }

    public static async getVideoDuration(file: File): Promise<number> {
        return new Promise((resolve, reject) => {
            const video = document.createElement('video');
            video.preload = 'metadata';
            video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src);
                resolve(Math.floor(video.duration));
            };
            video.src = URL.createObjectURL(file);
        });
    }

    public static isPDF(file: File): boolean {
        return file.type === 'application/pdf';
    }

    public static async loadFile(file: string): Promise<File> {
        const response = await fetch(file);

        return new File([ await response.blob() ], 'file');
    }

}
